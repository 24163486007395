import { MarketCountryCode } from '@backmarket/http-api'

export const COUNTRIES_WITHOUT_YOU_SAVE = [MarketCountryCode.JP]

export const COUNTRIES_WITH_IN_STOCK = [MarketCountryCode.JP]

export const COUNTRIES_WITH_TRADE_IN_PRICE_LABEL = [
  MarketCountryCode.US,
  MarketCountryCode.GB,
  MarketCountryCode.FR,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
]
